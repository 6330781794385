import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

export const TipoConta = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border: 2px solid #dfe1e6;
  border-radius: 3px;
  .ButtonCheck {
    min-width: 70px;
    background-color: #8850bf;
    color: #fff;
    border-radius: 5px;
    padding: 4px 25px 5px 30px;
    cursor: pointer;
    transition: 0.3s;
    label {
      cursor: pointer;
    }
  }
  .form-check-input:focus {
    border-color: transparent !important;
    box-shadow: transparent !important;
  }
  .form-check-input:checked {
    background-color: #8850bf;
    border-color: transparent !important;
    transition: 0.3s;
  }
`;

export const InpuTypeDate = styled.input`
  background-color: transparent;
  border: 1px solid;
  border-color: #ccc;
  box-sizing: border-box;
  height: 2.5rem;
  padding: 5px;
  color: inherit;
  cursor: inherit;
  font-size: 14px;
  min-width: 0;
  width: 100%;
  line-height: 1.4285714285714286;
`;
