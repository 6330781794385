import React, { ChangeEvent, useCallback, useState, useEffect } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import Spinner from '@atlaskit/spinner';

import { toast } from 'react-toastify';
import { format } from 'date-fns';

import { IConta, Lojas, IBancos } from './types';

import { Container, TipoConta, InpuTypeDate } from './styles';
import { Col, Row } from 'react-bootstrap';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import ToggleDefault from '~/components/ToggleDefault';
import useAuth from '~/hooks/useAuth';
import InputSelect from '~/components/Inputs/InputSelect';
import { Form } from 'react-bootstrap';
import InputText from '~/components/Inputs/InputText';
import Search from '~/components/Search';
import { Field } from '@atlaskit/form';
import InputMoney from '~/components/Inputs/InputMoney';
import { transformAsCurrency, formatCurrencyAsText } from '~/utils/functions';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

const ContaBancaria: React.FC = () => {
  const user = useAuth();
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [iniInicializado, setInitInicializado] = useState(false);
  const [formData, setFormData] = useState<IConta>({
    cod_cc: {
      value: '',
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    des_agencia: {
      value: '',
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
    des_cc: {
      value: '',
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
    val_saldo_inicial: {
      value: '',
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
    flg_inativa: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cod_loja: {
      value: { label: undefined, value: undefined },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cod_banco: {
      value: { label: 'Selecione...', value: undefined },
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
  });

  const [dataSaldoInicial, setDataSaldoInicial] = useState({
    value: '',
    isInvalid: true,
    isRequired: true,
    iniInicializado: false,
  });

  const [optionsSelectLoja, setOptionsSelectLoja] = useState([]);
  const [optionsBanco, setoptionsBanco] = useState([]);
  const [corrente, setCorrente] = useState<boolean>(false);
  const [investimento, setInvestimento] = useState<boolean>(false);
  const [caixa, setCaixa] = useState<boolean>(false);
  const [cofre, setCofre] = useState<boolean>(false);

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    setUpdate(false);
    setFormData({
      ...formData,
      cod_cc: {
        value: '',
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      des_agencia: {
        value: '',
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      des_cc: {
        value: '',
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      val_saldo_inicial: {
        value: '',
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },

      flg_inativa: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cod_loja: {
        value: { value: undefined, label: undefined },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cod_banco: {
        value: { label: 'Selecione...', value: undefined },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
    });

    setDataSaldoInicial({
      ...dataSaldoInicial,
      value: '',
      isInvalid: true,
      isRequired: true,
      iniInicializado: false,
    });
    setCorrente(false);
    setInvestimento(false);
    setCaixa(false);
    setCofre(false);
    setInitInicializado(false);
  }, [formData, dataSaldoInicial]);

  const handleLojaPadrao = useCallback(async () => {
    const { data } = await api.get(`/lojas/usuario`);
    if (data.success && data.data) {
      const lojaPadrao = data.data.filter((element: any) => {
        return element.flg_padrao === true;
      });
      setFormData({
        ...formData,
        cod_loja: {
          ...formData.cod_loja,
          value: {
            value: lojaPadrao[0].cod_loja,
            label: `${lojaPadrao[0].cod_loja <= 9 ? '0' : ''}${
              lojaPadrao[0].cod_loja
            } - ${lojaPadrao[0].des_loja} (${lojaPadrao[0].des_cidade})`,
          },
        },
      });
    }
  }, [formData]);

  useEffect(() => {
    if (formData.cod_loja.value.value === undefined) {
      handleLojaPadrao();
    }
  }, [formData.cod_loja.value.value, handleLojaPadrao]);

  /**
   * validacoes
   */

  const handleChangeNumeroAgencia = useCallback(
    (val: string, isInvalid: boolean) => {
      setFormData({
        ...formData,
        des_agencia: { ...formData.des_agencia, value: val, isInvalid },
      });
    },
    [formData],
  );

  const handleChangeNumeroConta = useCallback(
    (val: string) => {
      const validaDigito = val.includes('-');
      setFormData({
        ...formData,
        des_cc: { ...formData.des_cc, value: val, isInvalid: !validaDigito },
      });
    },
    [formData],
  );

  const handleChangeSaldoInicial = useCallback(
    (val: string) => {
      setFormData({
        ...formData,
        val_saldo_inicial: {
          ...formData.val_saldo_inicial,
          value: val,
          isInvalid: val === '',
        },
      });
    },
    [formData],
  );

  const handleChangeDataSaldoInicial = useCallback(
    (val: any) => {
      if (val.lenght === '') {
        setDataSaldoInicial({
          ...dataSaldoInicial,
          value: val,
          isInvalid: true,
          isRequired: true,
          iniInicializado: true,
        });
        return;
      }
      setDataSaldoInicial({
        ...dataSaldoInicial,
        value: val,
        isInvalid: false,
        iniInicializado: true,
      });
    },
    [dataSaldoInicial],
  );

  const handleChangeLoja = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setFormData({
        ...formData,
        cod_loja: { ...formData.cod_loja, value: val, isInvalid },
      });
    },
    [formData],
  );

  const handleChangeBanco = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setFormData({
        ...formData,
        cod_banco: { ...formData.cod_banco, value: val, isInvalid },
      });
    },
    [formData],
  );

  const handleFlagInativa = useCallback(() => {
    setFormData({
      ...formData,
      flg_inativa: {
        ...formData.flg_inativa,
        value: !formData.flg_inativa.value,
      },
    });
  }, [formData]);

  /**
   * Validações
   */
  const validaInputsParaSubmit = useCallback(
    (inputs: IConta): boolean => {
      if (!corrente && !investimento && !caixa && !cofre) {
        toast.warning('Tipo de conta deve ser informado');
        return true;
      }
      if (!inputs.cod_banco.value.value) {
        toast.warning('Instituição Bancária deve ser informada');
        return true;
      }
      if (!isUpdate && !inputs.des_cc.value.includes('-')) {
        toast.warning(
          'Dígito da Conta-Corrente deve ser informado. Utilize o caractere especial traço "-" para delimitá-lo. Exemplo: 12345678-0',
        );
        return true;
      }

      if (
        inputs.cod_banco.value === undefined ||
        inputs.des_agencia.value === '' ||
        inputs.des_cc.value === '' ||
        inputs.val_saldo_inicial.value === '' ||
        dataSaldoInicial.value === '' ||
        dataSaldoInicial.value === undefined
      ) {
        return true;
      }
      return false;
    },
    [corrente, dataSaldoInicial, investimento, caixa, cofre, isUpdate],
  );

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const { cod_cc, cod_banco, cod_loja, des_banco, des_loja } = row;

    setUpdate(true);

    const { data } = await api.get(`/contabancaria/${cod_cc}`);
    if (data.success && data.data) {
      setFormData({
        ...formData,
        cod_cc: {
          ...formData.cod_cc,
          value: data.data[0].cod_cc,
          isInvalid: data.data[0].cod_cc === null,
        },
        des_agencia: {
          ...formData.des_agencia,
          value: data.data[0].des_agencia,
          isInvalid: data.data[0].des_agencia === null,
        },
        des_cc: {
          ...formData.des_cc,
          value: data.data[0].des_cc,
          isInvalid: data.data[0].des_cc === null,
        },
        val_saldo_inicial: {
          ...formData.val_saldo_inicial,
          value: formatCurrencyAsText(data.data[0].val_saldo_inicial),
          isInvalid: data.data[0].val_saldo_inicial === null,
        },

        flg_inativa: {
          ...formData.flg_inativa,
          value: data.data[0].flg_inativa,
          isInvalid: data.data[0].flg_inativa === null,
        },
        cod_loja: {
          ...formData.cod_loja,
          value: { value: cod_loja, label: des_loja },

          isInvalid: data.data[0].cod_loja === null,
        },
        cod_banco: {
          ...formData.cod_banco,
          value: { value: cod_banco, label: des_banco },
          isInvalid: data.data[0].cod_banco === null,
        },
      });

      setDataSaldoInicial({
        ...dataSaldoInicial,
        value: format(
          createDateWithoutTimezone(data.data[0].dta_saldo_inicial),
          'yyy-MM-dd',
        ),
        isInvalid: data.data[0].dta_saldo_inicial === null,
      });

      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].tipo_cc === 0) {
          setCorrente(true);
        }
        if (data.data[i].tipo_cc === 1) {
          setInvestimento(true);
        }
        if (data.data[i].tipo_cc === 2) {
          setCaixa(true);
        }
        if (data.data[i].tipo_cc === 3) {
          setCofre(true);
        }
      }
    }
    setLoader(false);

    setShowSearch(false);
  };

  /**
   * Listagem da lojas no select
   */
  useEffect(() => {
    (async () => {
      if (user.user?.id !== undefined) {
        const { data } = await api.get(`/lojas/usuario`);

        if (data.success) {
          const options = data.data
            .filter((l: any) => l.flg_inativa !== true)
            .map((loja: Lojas) => ({
              label: `${
                loja.cod_loja.toString().length === 1
                  ? `0${loja.cod_loja}`
                  : loja.cod_loja
              } - ${loja.des_loja} (${loja.des_cidade})`,
              value: loja.cod_loja,
            }));
          setOptionsSelectLoja(options);
        }
      }
    })();
  }, [user.user?.id]);

  /**
   * Listagem dos bancos no select
   */
  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/bancos`);

      if (data.success) {
        const optionsBancos = data.data.map((banco: IBancos) => ({
          label: `${banco.cod_banco} - ${banco.des_banco}`,
          value: banco.cod_banco,
        }));

        setoptionsBanco(optionsBancos);
      }
    })();
  }, []);

  const onDelete = async () => {
    const { data } = await api.delete(`/conta/${formData?.cod_cc.value}`);

    if (data.success) {
      resetFormData();
      setShowSearch(true);
      setUpdate(false);
      toast.warning('Conta Inativada com Sucesso');
    }
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
    setShowSearch(false);
  };

  const handleSubmit = useCallback(async () => {
    setInitInicializado(true);
    if (validaInputsParaSubmit(formData)) {
      return;
    }
    try {
      if (isUpdate) {
        const { data } = await api.put(`/conta/${formData?.cod_cc.value}`, {
          cod_loja: formData.cod_loja.value.value,
          cod_banco: formData.cod_banco.value.value,
          des_agencia: formData.des_agencia.value,
          des_cc: formData.des_cc.value,
          val_saldo_inicial: transformAsCurrency(
            formData.val_saldo_inicial.value || '0',
          ),
          dta_saldo_inicial: dataSaldoInicial.value,
          flg_inativa: formData.flg_inativa.value,
          tipoConta: {
            corrente,
            investimento,
            caixa,
            cofre,
          },
        });

        if (data.success) {
          toast.success('Conta Atualizada com Sucesso');
          resetFormData();
          setShowSearch(true);
        } else {
          toast.warning(data.message);
        }
      } else {
        const { data } = await api.post('/contabancaria', {
          cod_loja: formData.cod_loja.value.value,
          cod_banco: formData.cod_banco.value.value,
          des_agencia: formData.des_agencia.value,
          des_cc: formData.des_cc.value,
          val_saldo_inicial: transformAsCurrency(
            formData.val_saldo_inicial.value || '0',
          ),
          dta_saldo_inicial: dataSaldoInicial.value,
          flg_inativa: formData.flg_inativa.value,
          tipoConta: {
            corrente,
            investimento,
            caixa,
            cofre,
          },
        });

        if (data.success) {
          toast.success('Conta Cadastrada com Sucesso');
          resetFormData();
          setShowSearch(false);
        } else {
          toast.warning(data.message);
        }
      }
    } finally {
      setLoader(false);
    }
  }, [
    corrente,
    investimento,
    caixa,
    cofre,
    dataSaldoInicial,
    formData,
    isUpdate,
    validaInputsParaSubmit,
    resetFormData,
  ]);

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={40}
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
        />
      )}
      {!showSearch && (
        <FormDefault
          codTela={40}
          title="Cadastro de Conta Bancária"
          codigoRegistro={[
            { value: formData.cod_cc.value, des_campo: 'Código' },
          ]}
          onSave={handleSubmit}
          onCancel={() => {
            setShowSearch(true);
            resetFormData();
          }}
          isUpdate={isUpdate}
          onNew={onNew}
          isDelete={false}
          onDelete={onDelete}
          deleteMessage="Conta bancária será inativada. Deseja continuar?"
          onClearFields={resetFormData}
          onReturnSearch={() => {
            setShowSearch(true);
            resetFormData();
          }}
        >
          <>
            <Row>
              <Col sm={12} md={6}>
                <InputSelect
                  options={optionsSelectLoja}
                  label="Lojas"
                  value={formData.cod_loja.value}
                  isDisabled={isUpdate}
                  isRequired={formData.cod_loja.isRequired}
                  setInvalid={formData.cod_loja.isInvalid}
                  iniInicializado={iniInicializado}
                  onChange={(newValue: any, isInvalid = true) => {
                    handleChangeLoja(newValue, isInvalid);
                  }}
                />
              </Col>
              <Col sm={12} md={6}>
                <Field name="buscar_por" defaultValue="" label="Tipo de Conta">
                  {() => (
                    <TipoConta>
                      <div
                        onClick={() => {
                          setCorrente(!corrente);
                        }}
                      >
                        <Form.Check
                          className="ButtonCheck"
                          type="checkbox"
                          label="Corrente"
                          checked={corrente}
                          onChange={() => null}
                          style={{
                            backgroundColor: corrente ? '#57069e' : '#8850bf',
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          setInvestimento(!investimento);
                        }}
                      >
                        <Form.Check
                          className="ButtonCheck"
                          type="checkbox"
                          label="Investimento"
                          checked={investimento}
                          onChange={() => null}
                          style={{
                            backgroundColor: investimento
                              ? '#57069e'
                              : '#8850bf',
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          setCaixa(!caixa);
                        }}
                      >
                        <Form.Check
                          className="ButtonCheck"
                          type="checkbox"
                          label="Caixa"
                          checked={caixa}
                          onChange={() => null}
                          style={{
                            backgroundColor: caixa ? '#57069e' : '#8850bf',
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          setCofre(!cofre);
                        }}
                      >
                        <Form.Check
                          className="ButtonCheck"
                          type="checkbox"
                          label="Cofre"
                          checked={cofre}
                          onChange={() => setCofre(!cofre)}
                          style={{
                            backgroundColor: cofre ? '#57069e' : '#8850bf',
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                    </TipoConta>
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={8}>
                <InputSelect
                  options={optionsBanco}
                  label="Instituição Bancária"
                  value={formData.cod_banco.value}
                  isDisabled={isUpdate}
                  isRequired={formData.cod_banco.isRequired}
                  setInvalid={formData.cod_banco.isInvalid}
                  iniInicializado={iniInicializado}
                  onChange={(newValue: any, isInvalid = true) => {
                    handleChangeBanco(newValue, isInvalid);
                  }}
                />
              </Col>

              <Col sm={12} md={2}>
                <InputText
                  label="Agência"
                  value={formData.des_agencia.value}
                  maxLength={5}
                  placeholder="Informe a Agência"
                  isDisabled={isUpdate}
                  isEmpty
                  isRequired={formData.des_agencia.isRequired}
                  setInvalid={formData.des_agencia.isInvalid}
                  iniInicializado={iniInicializado}
                  onChange={(newValue: string, isInvalid = true) => {
                    handleChangeNumeroAgencia(newValue, isInvalid);
                  }}
                />
              </Col>
              <Col sm={12} md={2}>
                <InputText
                  label="Conta"
                  value={formData.des_cc.value}
                  maxLength={15}
                  placeholder="Informe a Conta"
                  isEmpty
                  isDisabled={isUpdate}
                  isRequired={formData.des_cc.isRequired}
                  setInvalid={formData.des_cc.isInvalid}
                  iniInicializado={iniInicializado}
                  onChange={(newValue: string) => {
                    handleChangeNumeroConta(newValue);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={2}>
                <InputMoney
                  label="Saldo Inicial"
                  value={formData.val_saldo_inicial.value}
                  min={-99999.99}
                  max={99999.99}
                  placeholder="0,00"
                  isNull
                  isUndefined
                  isEmpty
                  isRequired={formData.val_saldo_inicial.isRequired}
                  setInvalid={formData.val_saldo_inicial.isInvalid}
                  iniInicializado={iniInicializado}
                  onChange={(newValue: string) => {
                    handleChangeSaldoInicial(newValue);
                  }}
                />
              </Col>
              <Col sm={12} md={2}>
                <Field
                  name="buscar_por"
                  defaultValue=""
                  label="Data do Saldo Inicial"
                >
                  {() => (
                    <div
                      style={{
                        borderBottom:
                          iniInicializado &&
                          (dataSaldoInicial.value === undefined ||
                            dataSaldoInicial.value === '')
                            ? '2px solid #cf1919'
                            : 'none',
                      }}
                    >
                      <InpuTypeDate
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChangeDataSaldoInicial(e.target.value);
                        }}
                        value={dataSaldoInicial.value}
                        type="date"
                      />
                    </div>
                  )}
                </Field>
              </Col>
              <Col sm={12} md={2}>
                <ToggleDefault
                  labelText="Inativa?"
                  setChecked={formData.flg_inativa.value}
                  onSwitch={handleFlagInativa}
                />
              </Col>
            </Row>
          </>
        </FormDefault>
      )}
    </Container>
  );
};

export default ContaBancaria;
